import type { MethodType } from 'broadcast-channel'
import { BroadcastChannel as BroadcastChannelImpl } from 'broadcast-channel'
import * as devalue from 'devalue'

export function useVoixBroadcast() {
  /**
   * Share state across browser tabs.
   * Original code from @wobsoriano - https://github.com/wobsoriano/pinia-shared-state
   *
   * @example
   *
   * Where the data is going to change / be shared
   *
   * ```ts
   * import { useVoixBroadcast } from '@voix/composables/useVoixBroadcast'
   * const broadcast = useVoixBroadcast()
   * broadcast.share('fieldupdate', [fielddata], 'native')
   * ```
   *
   *
   * Later in another tab:
   *
   * ```ts
   * import { useVoixBroadcast } from '@voix/composables/useVoixBroadcast'
   * const broadcast = useVoixBroadcast()
   * broadcast.recieve('fieldupdate', (data) => { [do something with the data] })
   * ```
   *
   * @param channelName - A property of a store state.
   * @param store - The store the plugin will augment.
   * @param data - The data to be shared.
   * @param type - 'native', 'idb', 'localstorage', 'node'.
   */
  const share = (
    channelName: string,
    store: any,
    type: MethodType = 'native',
  ) => {
    if (typeof window === 'undefined')
      return

    const channel = new BroadcastChannelImpl(channelName, { type })

    channel.postMessage({
      state: devalue.parse(devalue.stringify(store)),
    })

    return channel
  }

  const receive = (
    channelName: string,
    callback: (state: any) => void,
    type: MethodType = 'native',
  ) => {
    if (typeof window === 'undefined')
      return

    const channel = new BroadcastChannelImpl(channelName, { type })
    channel.onmessage = (evt) => {
      callback(evt.state)
    }
  }

  return { share, receive }
}
