import vVoixVisibility from '@voix/directives/v-voix-visibility'
import vVoixText from '@voix/directives/v-voix-text'
import vVoixPopper from '@voix/directives/v-voix-popper'
import { ref } from 'vue'
import type { Ref } from 'vue'
import type { VoixModuleOptionsInterface } from '@voix/types/modules.d'
import { defuArrayFn } from 'defu'

import { useSliceStore } from '@voix/store/sliceStore'
import { useLayoutStore } from '@voix/store/layoutStore'
import slicesConfig from 'virtual:voix-slices-config'
import layoutsConfig from 'virtual:voix-layouts-config'
import fetchSetup from './voix-plugin-utils/fetch-setup'

import authMiddlewareSetup from './voix-plugin-utils/auth-middleware-setup'
import voixBreakpointsSetup from './voix-plugin-utils/voix-breakpoints-setup'
import voixApplicationSetup from './voix-plugin-utils/voix-application-setup'

import { defineNuxtPlugin, useRuntimeConfig } from '#imports'

export default defineNuxtPlugin((NuxtApp) => {
  const voixConfig = useRuntimeConfig().public.voix as VoixModuleOptionsInterface
  const voixOptions = Object.assign({}, voixConfig.options)
  if (!voixOptions.languages || !voixOptions.languages.length) {
    voixOptions.languages = [
      {
        code: 'en',
        name: 'English',
        icon: '🇺🇸',
      },
    ]
  }

  let voix: { options: VoixModuleOptionsInterface, breakpoint: Ref<string>, fetch?: any } = {
    options: voixOptions,
    breakpoint: ref(''),
  }

  const sliceStore = useSliceStore()
  sliceStore.slices = slicesConfig

  const layoutStore = useLayoutStore()
  layoutStore.layouts = layoutsConfig

  NuxtApp.vueApp.directive('voix-text', vVoixText)
  NuxtApp.vueApp.directive('voix-visibility', vVoixVisibility)
  NuxtApp.vueApp.directive('voix-popper', vVoixPopper)

  voix = voixBreakpointsSetup(voix)
  voix = fetchSetup(voix)
  authMiddlewareSetup()
  voixApplicationSetup()

  // Injects into the application for global use
  return {
    provide: {
      voix,
    },
  }
})
