import { getCurrentBreakpoint } from '@voix/composables/useBreakpoints'
import { useBreakpointStore } from '@voix/store/breakpointStore'

export default (voix) => {
  const breakpointStore = useBreakpointStore()

  if (typeof window === 'undefined') {
    // TODO: Need to allow the setting of default size
    voix.breakpoint.value = 'default'
  }
  else {
    voix.breakpoint.value = getCurrentBreakpoint(window.innerWidth).name
    window.addEventListener('resize', () => {
      voix.breakpoint.value = getCurrentBreakpoint(window.innerWidth).name
    })
  }

  const breakpointTests = function (breakpointIdentifier: string, operator: string): boolean {
    const breakpoints = breakpointStore.breakpoints
    const testingBreakpoint = breakpoints.find(bp => bp.name === voix.breakpoint.value)
    const testingAgainstBreakpoint = breakpoints.find(bp => bp.name === breakpointIdentifier)

    const operators: { [key: string]: (a: string, b: string) => boolean } = {
      '<': (a: string, b: string) => a < b,
      '>': (a: string, b: string) => a > b,
      '<=': (a: string, b: string) => a <= b,
      '>=': (a: string, b: string) => a >= b,
    }

    if (testingBreakpoint && testingAgainstBreakpoint)
      return operators[operator](breakpoints.indexOf(testingBreakpoint), breakpoints.indexOf(testingAgainstBreakpoint))

    return false
  }

  Object.defineProperty(voix.breakpoint, 'isLessThan', {
    value: (breakpointIdentifier: string): boolean => {
      return breakpointTests(breakpointIdentifier, '<')
    },
  })
  Object.defineProperty(voix.breakpoint, 'isLessThanInclusive', {
    value: (breakpointIdentifier: string): boolean => {
      return breakpointTests(breakpointIdentifier, '<=')
    },
  })
  Object.defineProperty(voix.breakpoint, 'isGreaterThan', {
    value: (breakpointIdentifier: string): boolean => {
      return breakpointTests(breakpointIdentifier, '>')
    },
  })
  Object.defineProperty(voix.breakpoint, 'isGreaterThanInclusive', {
    value: (breakpointIdentifier: string): boolean => {
      return breakpointTests(breakpointIdentifier, '>=')
    },
  })
  Object.defineProperty(voix.breakpoint, 'isBetween', {
    value: (breakpointIdentifierMin: string, breakpointIdentifierMax: string): boolean => {
      return breakpointTests(breakpointIdentifierMin, '>') && breakpointTests(breakpointIdentifierMax, '<')
    },
  })
  Object.defineProperty(voix.breakpoint, 'isBetweenInclusive', {
    value: (breakpointIdentifierMin: string, breakpointIdentifierMax: string): boolean => {
      return breakpointTests(breakpointIdentifierMin, '>=') && breakpointTests(breakpointIdentifierMax, '<=')
    },
  })

  return voix
}
