<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useRoute } from '#imports'

const props = defineProps<{
  error: {
    statusCode: number
    message: string
    stack: string
  }
}>()

const statusCode = computed(() => {
  if (props.error?.statusCode)
    return props.error?.statusCode

  return 500
})

// Choose from an assortment of random witty titles
const randomTitle = computed(() => {
  const wittyErrorTitles = [
    'Oops, something went sideways!',
    'Wit not found',
    'Oopsie daisy! Gremlins in the system',
    'The page you\'re looking for took a coffee break',
    'I\'m a teapot, not a webpage!',
    'Uh-oh! The code gremlins are at it again',
    'Lost in the digital wilderness',
    'Brain currently overloaded',
    'Hold your horses! This page has gone rogue',
    'The page you seek is in another castle',
    'Unicorns ate our server cables',
    'Oops! Looks like we misplaced that page',
    'The monkeys typed something wrong',
    'The gremlins are tinkering with our pixels',
    'This page is on vacation',
    'Danger, Will Robinson! Error detected',
    'Whoopsie! Our hamsters fell off the wheel',
    'System failure: Cats spilled coffee on the server',
  ]

  // Randomly select a witty title from the array
  return wittyErrorTitles[Math.floor(Math.random() * wittyErrorTitles.length)]
})

const message = computed(() => {
  if (props.error?.message)
    return props.error?.message

  return 'There was a problem finding your page. Try heading back to the homepage'
})

onMounted(() => {
  if (typeof window === 'object'
    && props.error?.message
    && props.error?.message.includes('Failed to fetch dynamically imported module')
  ) {
    // very likely that we released a new version so let's try to refresh
    let url = window.location.href
    const queryString = new URLSearchParams(window.location.search)

    if (!queryString.has('e-fresh')) {
      // only if fresh is not on the page
      if (url.includes('?'))
        url += `&e-fresh=${uuidv4()}`
      else
        url += `?e-fresh=${uuidv4()}`

      window.location.href = url
    }
  }
})
</script>

<template>
  <div class="grid md:grid-cols-2 font-sans2">
    <div
      class="relative md:h-screen flex md:items-center justify-center md:justify-end overflow-hidden text-[150px] md:text-[200px] font-black bg-slate-900"
    >
      <div class="-mt-32 flex flex-col items-end max-h-[50vh]">
        <span class="translate-y-16 md:translate-x-8 text-slate-800">{{ statusCode }}</span>
        <div class="text-5xl font-thin text-slate-700">
          {{ message }}
        </div>
      </div>
      <div
        class="bg-gradient-to-t md:bg-gradient-to-l from-black to-slate-900/0 absolute inset-0 to-30% border-r border-slate-300"
      />
    </div>
    <div class="flex flex-col justify-center bg-slate-100">
      <ClientOnly>
        <div class="py-12 px-8 md:px-20 text-slate-900">
          <div class="prose prose-xl">
            <h6 class="text-base font-medium text-slate-400 uppercase">
              {{ randomTitle }}
            </h6>
            <div class="text-xs text-slate-700 font-sans">
              <pre>
                  {{ message }}
                </pre>
            </div>
          </div>
          <a href="/" class="inline-block mt-12 px-4 py-2 bg-slate-700 text-slate-200 rounded">Go to homepage</a>
        </div>
      </ClientOnly>
      <div class="absolute bottom-0 left-0 right-0 bg-gray-200 p-4 rounded ">
        <div class="overflow-x-scroll " v-html="error.stack" />
      </div>
    </div>
  </div>
</template>
