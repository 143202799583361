import { default as _91_46_46_46slug_93jbn41TB8YeMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/playground/pages/[...slug].vue?macro=true";
import { default as auth5HfEGOulRyMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/playground/pages/auth.vue?macro=true";
import { default as index7EvQ2moTH3Meta } from "/Users/cdm/code/turbo/super-devise/apps/voix/playground/pages/voix/specials/index.vue?macro=true";
import { default as voix_45debuggPrVTd6v6oMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/voix-debug.vue?macro=true";
import { default as IndexGBBXoV7Jx2Meta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/settings-menu/Index.vue?macro=true";
import { default as loginDv4LkeJ62DMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/auth/login.vue?macro=true";
import { default as callbackoqvhxlYkHVMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/auth/callback.vue?macro=true";
import { default as studio9VDH3bQHawMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/studio.vue?macro=true";
import { default as IndexLORnBJTtKKMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/head-tags/Index.vue?macro=true";
import { default as EditYDG16P7bZYMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/head-tags/Edit.vue?macro=true";
import { default as CreateyrQlfmi4CYMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/head-tags/Create.vue?macro=true";
import { default as IndexVtyweULUqTMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/pages/Index.vue?macro=true";
import { default as Create5ycygXnOisMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/pages/Create.vue?macro=true";
import { default as Translate4QN3pahBKVMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/pages/Translate.vue?macro=true";
import { default as CopyOIuempQnekMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/pages/Copy.vue?macro=true";
import { default as Index6ajS4OzSVzMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/releases/Index.vue?macro=true";
import { default as IndexDyNGdAu8nFMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/redirects/Index.vue?macro=true";
import { default as EditYkEXF9ELKjMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/redirects/Edit.vue?macro=true";
import { default as CreateJMN7A5FtKjMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/redirects/Create.vue?macro=true";
import { default as IndexZG27qdGSlbMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/users/Index.vue?macro=true";
import { default as Createef3vFuTIAkMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/users/Create.vue?macro=true";
import { default as EditYPgCCsybk1Meta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/users/Edit.vue?macro=true";
import { default as SlicesViewwHEpgE8FXcMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/components/templatizer/views/SlicesView.vue?macro=true";
import { default as ModelsViewsnrNiBYvJSMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/components/templatizer/views/ModelsView.vue?macro=true";
import { default as TemplateViewqlNkEaPVDZMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/components/templatizer/views/TemplateView.vue?macro=true";
import { default as DesignGuideViewJiQgWBOA1gMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/components/templatizer/views/DesignGuideView.vue?macro=true";
import { default as templatizer1RGOoksoR4Meta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/templatizer.vue?macro=true";
import { default as voixm9uY9AkFj4Meta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/voix.vue?macro=true";
import { default as SlicePreview8JXh39U5MuMeta } from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/components/templatizer/views/SlicePreview.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93jbn41TB8YeMeta?.name ?? "slug",
    path: _91_46_46_46slug_93jbn41TB8YeMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93jbn41TB8YeMeta || {},
    alias: _91_46_46_46slug_93jbn41TB8YeMeta?.alias || [],
    redirect: _91_46_46_46slug_93jbn41TB8YeMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/playground/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: auth5HfEGOulRyMeta?.name ?? "auth",
    path: auth5HfEGOulRyMeta?.path ?? "/auth",
    meta: auth5HfEGOulRyMeta || {},
    alias: auth5HfEGOulRyMeta?.alias || [],
    redirect: auth5HfEGOulRyMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/playground/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: index7EvQ2moTH3Meta?.name ?? "voix-specials",
    path: index7EvQ2moTH3Meta?.path ?? "/voix/specials",
    meta: index7EvQ2moTH3Meta || {},
    alias: index7EvQ2moTH3Meta?.alias || [],
    redirect: index7EvQ2moTH3Meta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/playground/pages/voix/specials/index.vue").then(m => m.default || m)
  },
  {
    name: voix_45debuggPrVTd6v6oMeta?.name ?? "voix-debug",
    path: voix_45debuggPrVTd6v6oMeta?.path ?? "/voix-debug",
    meta: voix_45debuggPrVTd6v6oMeta || {},
    alias: voix_45debuggPrVTd6v6oMeta?.alias || [],
    redirect: voix_45debuggPrVTd6v6oMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/voix-debug.vue").then(m => m.default || m)
  },
  {
    name: voixm9uY9AkFj4Meta?.name ?? "voix",
    path: voixm9uY9AkFj4Meta?.path ?? "/voix",
    children: [
  {
    name: IndexGBBXoV7Jx2Meta?.name ?? "voix-settings",
    path: IndexGBBXoV7Jx2Meta?.path ?? "",
    meta: IndexGBBXoV7Jx2Meta || {},
    alias: IndexGBBXoV7Jx2Meta?.alias || [],
    redirect: IndexGBBXoV7Jx2Meta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/settings-menu/Index.vue").then(m => m.default || m)
  },
  {
    name: loginDv4LkeJ62DMeta?.name ?? "voix-login",
    path: loginDv4LkeJ62DMeta?.path ?? "login",
    meta: loginDv4LkeJ62DMeta || {},
    alias: loginDv4LkeJ62DMeta?.alias || [],
    redirect: loginDv4LkeJ62DMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: callbackoqvhxlYkHVMeta?.name ?? "voix-auth-callback",
    path: callbackoqvhxlYkHVMeta?.path ?? "auth/callback",
    meta: callbackoqvhxlYkHVMeta || {},
    alias: callbackoqvhxlYkHVMeta?.alias || [],
    redirect: callbackoqvhxlYkHVMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/auth/callback.vue").then(m => m.default || m)
  },
  {
    name: studio9VDH3bQHawMeta?.name ?? "voix-studio",
    path: studio9VDH3bQHawMeta?.path ?? "studio",
    meta: studio9VDH3bQHawMeta || {},
    alias: studio9VDH3bQHawMeta?.alias || [],
    redirect: studio9VDH3bQHawMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/studio.vue").then(m => m.default || m)
  },
  {
    path: IndexLORnBJTtKKMeta?.path ?? "head-tags",
    name: IndexLORnBJTtKKMeta?.name ?? "voix-settings-head-tags",
    meta: IndexLORnBJTtKKMeta || {},
    alias: IndexLORnBJTtKKMeta?.alias || [],
    redirect: IndexLORnBJTtKKMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/head-tags/Index.vue").then(m => m.default || m)
  },
  {
    path: EditYDG16P7bZYMeta?.path ?? "head-tags/:id",
    name: EditYDG16P7bZYMeta?.name ?? "voix-settings-head-tags-edit",
    meta: EditYDG16P7bZYMeta || {},
    alias: EditYDG16P7bZYMeta?.alias || [],
    redirect: EditYDG16P7bZYMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/head-tags/Edit.vue").then(m => m.default || m)
  },
  {
    path: CreateyrQlfmi4CYMeta?.path ?? "head-tags/create",
    name: CreateyrQlfmi4CYMeta?.name ?? "voix-settings-head-tags-create",
    meta: CreateyrQlfmi4CYMeta || {},
    alias: CreateyrQlfmi4CYMeta?.alias || [],
    redirect: CreateyrQlfmi4CYMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/head-tags/Create.vue").then(m => m.default || m)
  },
  {
    path: IndexVtyweULUqTMeta?.path ?? "pages",
    name: IndexVtyweULUqTMeta?.name ?? "voix-settings-pages",
    meta: IndexVtyweULUqTMeta || {},
    alias: IndexVtyweULUqTMeta?.alias || [],
    redirect: IndexVtyweULUqTMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/pages/Index.vue").then(m => m.default || m)
  },
  {
    path: Create5ycygXnOisMeta?.path ?? "pages/create",
    name: Create5ycygXnOisMeta?.name ?? "voix-settings-pages-create",
    meta: Create5ycygXnOisMeta || {},
    alias: Create5ycygXnOisMeta?.alias || [],
    redirect: Create5ycygXnOisMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/pages/Create.vue").then(m => m.default || m)
  },
  {
    path: Translate4QN3pahBKVMeta?.path ?? "pages/translate",
    name: Translate4QN3pahBKVMeta?.name ?? "voix-settings-pages-translate",
    meta: Translate4QN3pahBKVMeta || {},
    alias: Translate4QN3pahBKVMeta?.alias || [],
    redirect: Translate4QN3pahBKVMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/pages/Translate.vue").then(m => m.default || m)
  },
  {
    path: CopyOIuempQnekMeta?.path ?? "pages/copy",
    name: CopyOIuempQnekMeta?.name ?? "voix-settings-pages-copy",
    meta: CopyOIuempQnekMeta || {},
    alias: CopyOIuempQnekMeta?.alias || [],
    redirect: CopyOIuempQnekMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/pages/Copy.vue").then(m => m.default || m)
  },
  {
    path: Index6ajS4OzSVzMeta?.path ?? "releases",
    name: Index6ajS4OzSVzMeta?.name ?? "voix-settings-releases",
    meta: Index6ajS4OzSVzMeta || {},
    alias: Index6ajS4OzSVzMeta?.alias || [],
    redirect: Index6ajS4OzSVzMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/releases/Index.vue").then(m => m.default || m)
  },
  {
    path: IndexDyNGdAu8nFMeta?.path ?? "redirects",
    name: IndexDyNGdAu8nFMeta?.name ?? "voix-settings-redirects",
    meta: IndexDyNGdAu8nFMeta || {},
    alias: IndexDyNGdAu8nFMeta?.alias || [],
    redirect: IndexDyNGdAu8nFMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/redirects/Index.vue").then(m => m.default || m)
  },
  {
    path: EditYkEXF9ELKjMeta?.path ?? "redirects/:id",
    name: EditYkEXF9ELKjMeta?.name ?? "voix-settings-redirects-edit",
    meta: EditYkEXF9ELKjMeta || {},
    alias: EditYkEXF9ELKjMeta?.alias || [],
    redirect: EditYkEXF9ELKjMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/redirects/Edit.vue").then(m => m.default || m)
  },
  {
    path: CreateJMN7A5FtKjMeta?.path ?? "redirects/create",
    name: CreateJMN7A5FtKjMeta?.name ?? "voix-settings-redirects-create",
    meta: CreateJMN7A5FtKjMeta || {},
    alias: CreateJMN7A5FtKjMeta?.alias || [],
    redirect: CreateJMN7A5FtKjMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/redirects/Create.vue").then(m => m.default || m)
  },
  {
    path: IndexZG27qdGSlbMeta?.path ?? "users",
    name: IndexZG27qdGSlbMeta?.name ?? "voix-settings-users",
    meta: IndexZG27qdGSlbMeta || {},
    alias: IndexZG27qdGSlbMeta?.alias || [],
    redirect: IndexZG27qdGSlbMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/users/Index.vue").then(m => m.default || m)
  },
  {
    path: Createef3vFuTIAkMeta?.path ?? "users/create",
    name: Createef3vFuTIAkMeta?.name ?? "voix-settings-users-create",
    meta: Createef3vFuTIAkMeta || {},
    alias: Createef3vFuTIAkMeta?.alias || [],
    redirect: Createef3vFuTIAkMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/users/Create.vue").then(m => m.default || m)
  },
  {
    path: EditYPgCCsybk1Meta?.path ?? "users/:id",
    name: EditYPgCCsybk1Meta?.name ?? "voix-settings-users-edit",
    meta: EditYPgCCsybk1Meta || {},
    alias: EditYPgCCsybk1Meta?.alias || [],
    redirect: EditYPgCCsybk1Meta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/users/Edit.vue").then(m => m.default || m)
  },
  {
    name: templatizer1RGOoksoR4Meta?.name ?? "voix-templatizer",
    path: templatizer1RGOoksoR4Meta?.path ?? "templatizer",
    children: [
  {
    name: SlicesViewwHEpgE8FXcMeta?.name ?? "templatizer-slices",
    path: SlicesViewwHEpgE8FXcMeta?.path ?? "",
    meta: SlicesViewwHEpgE8FXcMeta || {},
    alias: SlicesViewwHEpgE8FXcMeta?.alias || [],
    redirect: SlicesViewwHEpgE8FXcMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/components/templatizer/views/SlicesView.vue").then(m => m.default || m)
  },
  {
    path: ModelsViewsnrNiBYvJSMeta?.path ?? "models",
    name: ModelsViewsnrNiBYvJSMeta?.name ?? "templatizer-models",
    meta: ModelsViewsnrNiBYvJSMeta || {},
    alias: ModelsViewsnrNiBYvJSMeta?.alias || [],
    redirect: ModelsViewsnrNiBYvJSMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/components/templatizer/views/ModelsView.vue").then(m => m.default || m)
  },
  {
    path: TemplateViewqlNkEaPVDZMeta?.path ?? "templates/:templateName",
    name: TemplateViewqlNkEaPVDZMeta?.name ?? "templatizer-template",
    meta: TemplateViewqlNkEaPVDZMeta || {},
    alias: TemplateViewqlNkEaPVDZMeta?.alias || [],
    redirect: TemplateViewqlNkEaPVDZMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/components/templatizer/views/TemplateView.vue").then(m => m.default || m)
  },
  {
    path: DesignGuideViewJiQgWBOA1gMeta?.path ?? "design-guide",
    name: DesignGuideViewJiQgWBOA1gMeta?.name ?? "templatizer-design-guide",
    meta: DesignGuideViewJiQgWBOA1gMeta || {},
    alias: DesignGuideViewJiQgWBOA1gMeta?.alias || [],
    redirect: DesignGuideViewJiQgWBOA1gMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/components/templatizer/views/DesignGuideView.vue").then(m => m.default || m)
  }
],
    meta: templatizer1RGOoksoR4Meta || {},
    alias: templatizer1RGOoksoR4Meta?.alias || [],
    redirect: templatizer1RGOoksoR4Meta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/templatizer.vue").then(m => m.default || m)
  }
],
    meta: voixm9uY9AkFj4Meta || {},
    alias: voixm9uY9AkFj4Meta?.alias || [],
    redirect: voixm9uY9AkFj4Meta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/pages/voix.vue").then(m => m.default || m)
  },
  {
    path: SlicePreview8JXh39U5MuMeta?.path ?? "/slice-preview/:sliceName",
    name: SlicePreview8JXh39U5MuMeta?.name ?? "templatizer-slice",
    meta: SlicePreview8JXh39U5MuMeta || {},
    alias: SlicePreview8JXh39U5MuMeta?.alias || [],
    redirect: SlicePreview8JXh39U5MuMeta?.redirect || undefined,
    component: () => import("/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/components/templatizer/views/SlicePreview.vue").then(m => m.default || m)
  }
]