import { defineStore } from 'pinia'
import type { SliceConfigInterface, SliceInterface, SlicePropertiesInterface, SliceSettingsInterface } from '@voix/types'
import { v4 as uuidv4 } from 'uuid'

const useSliceStore = defineStore('sliceStore', {
  state: () => ({} as {
    slices: Record<string, SliceConfigInterface>
  }),

  actions: {
    buildSliceDataFromSliceConfig(component: string, slot: string): SliceInterface {
      const sliceConfiguration = this.getSlice(component)

      if (!sliceConfiguration)
        throw new Error(`Slice configuration not found for component: ${component}`)

      return {
        id: uuidv4(),
        type: 'slice',
        component,
        hydration_mode: sliceConfiguration.hydrationMode ?? 'load',
        // TODO: This should be different if it's added via a slot
        slot: slot || 'default',
        label: sliceConfiguration.name.label,
        fields: [],
        elements: [],
        properties: {
          settings: [] as Array<SliceSettingsInterface>,
        } as SlicePropertiesInterface,
        administration: {
          gui: {
            open: false,
            el: null,
            hydrationOptions: sliceConfiguration.hydrationOptions,
          },
          permissions: [],
        },
      }
    },
  },

  getters: {
    getSlice:
      state =>
        (sliceName: string): SliceConfigInterface | null => {
          const slice = state.slices[sliceName as keyof typeof state.slices]
          if (slice)
            return slice

          return null
        },

    getSliceComponentNameFromLabelAndGroup: state => (sliceName: SliceConfigInterface['name']): string | null => {
      if (!sliceName.label || sliceName.label === '')
        return null

      // Loop through slices and find the one with the matching label and group
      for (const sliceKey in state.slices) {
        const slice = state.slices[sliceKey as keyof typeof state.slices]
        if (slice.name.label === sliceName.label && slice.name.group === sliceName.group)
          return sliceKey
      }

      return null
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.on('voix-slice-update', (data) => {
    const key = Object.keys(data)[0]
    if (key) {
      const sliceStore = useSliceStore()
      sliceStore.slices[key] = data[key]
      // eslint-disable-next-line no-console
      console.log(`Slice updated: ${sliceStore.slices[key].name.label}`, sliceStore.slices[key].fields)
    }
  })
}

export { useSliceStore }
