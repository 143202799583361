import revive_payload_client_Hjmf4WeR2N from "/Users/cdm/code/turbo/super-devise/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ySWanUoDla from "/Users/cdm/code/turbo/super-devise/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Or2oaplyd8 from "/Users/cdm/code/turbo/super-devise/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rpN6vpLoWw from "/Users/cdm/code/turbo/super-devise/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_I8AhPB5cLY from "/Users/cdm/code/turbo/super-devise/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_rZ2LG0wREi from "/Users/cdm/code/turbo/super-devise/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/cdm/code/turbo/super-devise/apps/voix/playground/.nuxt/components.plugin.mjs";
import prefetch_client_GGXNTOA1qA from "/Users/cdm/code/turbo/super-devise/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_R0egfK6XcH from "/Users/cdm/code/turbo/super-devise/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_f1SKBejfl8 from "/Users/cdm/code/turbo/super-devise/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import voix_plugin_CDq5Tu5LPY from "/Users/cdm/code/turbo/super-devise/apps/voix/src/runtime/plugins/voix.plugin.ts";
import chunk_reload_client_Wpj0cDp0U1 from "/Users/cdm/code/turbo/super-devise/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_Hjmf4WeR2N,
  unhead_ySWanUoDla,
  router_Or2oaplyd8,
  payload_client_rpN6vpLoWw,
  check_outdated_build_client_I8AhPB5cLY,
  plugin_vue3_rZ2LG0wREi,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GGXNTOA1qA,
  composition_R0egfK6XcH,
  i18n_f1SKBejfl8,
  voix_plugin_CDq5Tu5LPY,
  chunk_reload_client_Wpj0cDp0U1
]