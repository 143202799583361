import type {
  BreakpointInterface,
  DropdownOptionInterface,
  ElementInterface,
  GroupSettingsInterface,
  QuerySettingsInterface,
  SettingsInterface,
  SliceSettingsInterface,
} from '@voix/types'

import type { TextFieldPropertiesBreakpointInterface } from '@voix/components/fields/text/types'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import { usePageStore } from '../store/pageStore'
import { useBreakpointStore } from './../store/breakpointStore'

// TODO: Create a wrapper function that will return all breakpoints

function getCurrentBreakpoint(width: number) {
  const breakpointStore = useBreakpointStore()
  const breakpoints = JSON.parse(
    JSON.stringify(breakpointStore.getAllBreakpoints),
  ).reverse()
  const breakpoint = breakpoints.find(
    (bp: BreakpointInterface) => bp.minWidth <= width,
  )
  return breakpoint
}

function getApplicableBreakpoints(width: number) {
  const breakpointStore = useBreakpointStore()
  const breakpoints = breakpointStore.getAllBreakpoints
  const breakpoint = breakpoints.filter(
    breakpoint => breakpoint.minWidth <= width,
  )
  return breakpoint
}

function removeEmptyValues(obj: any) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName].length === 0)
      delete obj[propName]
    else if (typeof obj[propName] === 'object')
      removeEmptyValues(obj[propName])
  }
  return obj
}

function findBreakpointSettings(property: Array<
    | TextFieldPropertiesBreakpointInterface
    | GroupSettingsInterface
    | SliceSettingsInterface
    | QuerySettingsInterface
  >) {
  if (property && property.length) {
    const breakpointStore = useBreakpointStore()
    const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 1200

    // Filter the breakpoints sizes that are applicable ot the
    // current window width and sort them by accuracy (ascending)
    const breakpointWidths = breakpointStore.breakpoints
      .map(breakpoint => breakpoint.minWidth)
      .filter(minWidth => minWidth <= windowWidth)
      .sort((a, b) => {
        return a - b
      })

    // Let's walk it back until we find the first breakpoint that is
    // less than the current window
    let finalSettings = {}

    breakpointWidths.forEach((breakpointWidth) => {
      const breakpoint = breakpointStore.breakpoints.find(
        breakpoint => breakpoint.minWidth === breakpointWidth,
      )

      if (breakpoint) {
        const propertyBreakpoint = Object.assign(
          {},
          property.find((property) => {
            if (property.breakpoint === breakpoint.name)
              return property
            return false
          }),
        )

        if (Object.keys(propertyBreakpoint).length > 0) {
          const breakpointSettings = removeEmptyValues(
            cloneDeep(propertyBreakpoint),
          )
          finalSettings = merge(finalSettings, breakpointSettings)
        }
      }
    })
    return finalSettings
  }
  return null
}

function sortBreakpoints(settings: Array<SettingsInterface>) {
  if (settings) {
    const breakpointStore = useBreakpointStore()

    //  const templateOrder = ['default', 'sm', 'md', 'lg', 'xl', '2xl']
    const breakpoints = breakpointStore.getAllBreakpoints
    const templateOrder = breakpoints.map((breakpoint) => {
      return breakpoint.name
    })

    // Reorder breakpoints to be in the order of smallest to largest
    return settings.slice().sort((a, b) => {
      return (
        templateOrder.indexOf(a.breakpoint)
        - templateOrder.indexOf(b.breakpoint)
      )
    })
  }
  return []
}

function breakpointOptions(settings?: Array<SettingsInterface>) {
  const { breakpoints } = useBreakpointStore()

  const options: Array<DropdownOptionInterface> = []

  breakpoints.forEach((breakpoint) => {
    const currentPropertyBreakpoint = settings
      ? settings.find(
        propertyBreakpoint =>
          propertyBreakpoint.breakpoint === breakpoint.name,
      )
      : null

    options.push({
      label: currentPropertyBreakpoint
        ? `${breakpoint.name} (${breakpoint.minWidth}px) | Added`
        : `${breakpoint.name} (${breakpoint.minWidth}px)`,
      value: breakpoint.name,
      key: breakpoint.name,
    })
  })
  return options
}

function addBreakpoint(element: ElementInterface,
  settings:
  | GroupSettingsInterface
  | SliceSettingsInterface
  | QuerySettingsInterface) {
  if (element.id) {
    const pageStore = usePageStore()
    const currentPageElement = pageStore.findElement(element.id)?.element

    if (currentPageElement && currentPageElement.properties) {
      if (!currentPageElement.properties.settings)
        currentPageElement.properties.settings = []

      currentPageElement.properties.settings.push(Object.assign({}, settings))
    }
  }
}

function updateBreakpoint(element: ElementInterface,
  settingsIndex: number,
  settings:
  | GroupSettingsInterface
  | SliceSettingsInterface
  | QuerySettingsInterface) {
  if (element.id) {
    const pageStore = usePageStore()
    const currentPageElement = pageStore.findElement(element.id)?.element

    if (
      currentPageElement
      && currentPageElement.properties
      && currentPageElement.properties.settings
      && currentPageElement.properties.settings[settingsIndex]
    ) {
      currentPageElement.properties.settings[settingsIndex] = Object.assign(
        {},
        settings,
      )
    }
  }
}

function removeBreakpoint(element: ElementInterface,
  breakpoint:
  | GroupSettingsInterface
  | SliceSettingsInterface
  | QuerySettingsInterface) {
  if (element?.properties?.settings) {
    element.properties.settings.splice(
      element.properties.settings.indexOf(breakpoint),
      1,
    )
  }
}

function breakPointIsLess(currentBreakpointValue: string, testBreakpointValue: string) {
  const bpOptions = breakpointOptions()
  bpOptions.forEach((option) => {
    if (option.value === testBreakpointValue)
      return true

    if (option.value === currentBreakpointValue)
      return false
  })
}

export {
  findBreakpointSettings,
  sortBreakpoints,
  getCurrentBreakpoint,
  getApplicableBreakpoints,
  breakpointOptions,
  addBreakpoint,
  updateBreakpoint,
  removeBreakpoint,
  breakPointIsLess,
}
