import type { Directive } from 'vue'

import { createPopper } from '@popperjs/core/lib/popper-lite.js'
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow.js'
import flip from '@popperjs/core/lib/modifiers/flip.js'

const vVoixPopper: Directive<any, any> = {
  mounted(el: HTMLElement, binding: any) {
    binding.popper = document.createElement('div')
    binding.popper.className = 'z-[5000000000] font-voix-studio text-sm bg-gray-800 text-gray-300 border border-gray-600 rounded shadow p-2 opacity-0 pointer-events-none transition-all duration-200 ease-out'

    binding.popper.innerHTML = binding.value
    document.getElementsByTagName('body')[0].appendChild(binding.popper)

    binding.popperInstance = createPopper(el, binding.popper, {
      modifiers: [preventOverflow, flip],
    })

    el.addEventListener('mouseenter', () => {
      binding.popper.classList.remove('opacity-0')
      binding.popper.classList.add('opacity-100')
      binding.popperInstance.update()
    })

    el.addEventListener('mouseleave', () => {
      binding.popper.classList.remove('opacity-100')
      binding.popper.classList.add('opacity-0')
    })
  },

  updated(el: HTMLElement, binding: any) {
    if (binding.popper)
      binding.popper.innerHTML = binding.value
  },

  unmounted(el: HTMLElement, binding: any) {
    if (binding.popperInstance)
      binding.popperInstance.destroy()

    if (binding.popper)
      binding.popper.remove()
  },
}

export default vVoixPopper
