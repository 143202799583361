<script setup lang="ts">

</script>

<template>
  <div>
    <div class="flex flex-col items-center justify-center bg-gray-500 bg-cover text-center text-abs-white min-h-screen">
      <div class="text-5xl uppercase title text-white pt-16 mb-8 font-thin">
        404 ERROR
        <div class="pt-1 text-sm uppercase font-bold text-slate-300">
          Sorry, we can't find that page.
        </div>
      </div>
    </div>
  </div>
</template>
