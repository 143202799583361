import { usePageStore } from '@voix/store/pageStore'

export default (voix: any) => {
  // Setup the fetch to add the x-voix-site header
  voix.fetch = (url: string, options?: { headers?: Headers }): Promise<Response> => {
    // Setup the headers if they don't exist on the options
    const theOptions = options || {}
    if (!theOptions?.headers)
      theOptions.headers = new Headers()

    const pageStore = usePageStore()
    // Append x-voix-site to the headers which is the current site id.
    theOptions.headers.append('x-voix-site', pageStore.currentPage?.site_id ? pageStore.currentPage.site_id.toString() : '0')

    // return the fetch
    return fetch(url, theOptions)
  }
  return voix
}
