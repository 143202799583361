import { useAdminStore } from '@voix/store/adminStore'
import { useCookie } from '#imports'

export default () => {
  const adminStore = useAdminStore()

  const releasePreviewCookie = useCookie('voix-release-preview')
  if (releasePreviewCookie.value)
    adminStore.releaseId = releasePreviewCookie.value.toString()
}
