import type { DirectiveBinding } from 'vue'

import type { TextFieldPropertiesInterface } from '@voix/components/fields/text/types'

import { findBreakpointSettings } from './../composables/useBreakpoints'

function updateStyles(el: HTMLElement, binding: DirectiveBinding) {
  const slice = binding.value.slice
  const field = binding.value.field

  const breakpointSettings = findBreakpointSettings(
    slice.fields[field].properties,
  ) as TextFieldPropertiesInterface | null

  if (breakpointSettings) {
    if (breakpointSettings.font)
      el.style.fontFamily = breakpointSettings.font

    if (breakpointSettings.fontSize) {
      // If the value doesn't have a unit size we'll assume its in pixels
      if (/^\d+$/.test(breakpointSettings.fontSize)) {
        el.style.fontSize = `${breakpointSettings.fontSize}px`
      }
      else {
        if (breakpointSettings.fontSize)
          el.style.fontSize = breakpointSettings.fontSize
        else
          el.style.removeProperty('font-size')
      }
    }

    if (breakpointSettings.fontWeight) {
      if (breakpointSettings.fontWeight)
        el.style.fontWeight = breakpointSettings.fontWeight
      else
        el.style.removeProperty('font-weight')
    }

    if (breakpointSettings.letterSpacing) {
      // Is the string only a number? If so, we'll assume it's in pixels
      if (/^\d+$/.test(breakpointSettings.letterSpacing)) {
        el.style.letterSpacing = `${breakpointSettings.letterSpacing}px`
      }
      else {
        if (breakpointSettings.letterSpacing)
          el.style.letterSpacing = breakpointSettings.letterSpacing
        else
          el.style.removeProperty('letter-spacing')
      }
    }

    if (breakpointSettings.lineHeight) {
      // Is the string only a number? If so, we'll assume it's in pixels
      if (/^\d+$/.test(breakpointSettings.lineHeight)) {
        el.style.lineHeight = `${breakpointSettings.lineHeight}px`
      }
      else {
        if (breakpointSettings.lineHeight)
          el.style.lineHeight = breakpointSettings.lineHeight
        else
          el.style.removeProperty('line-height')
      }
    }

    if (breakpointSettings.textAlign) {
      if (breakpointSettings.textAlign)
        el.style.textAlign = breakpointSettings.textAlign
      else
        el.style.removeProperty('text-align')
    }

    if (breakpointSettings.columns)
      el.style.columns = breakpointSettings.columns.toString()
  }
}

const vVoixText = {
  // called before bound element's attributes
  // or event listeners are applied
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value.slice && binding.value.field) {
      updateStyles(el, binding)
      // Update when the window resizes
      window.addEventListener('resize', () => updateStyles(el, binding))
    }
  },

  updated(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value.slice && binding.value.field)
      updateStyles(el, binding)
  },
}

export default vVoixText
