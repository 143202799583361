import { defineStore } from 'pinia'
import queryString from 'query-string'
import { useVoixBroadcast } from '@voix/composables/useVoixBroadcast'
import { computed, reactive, ref } from 'vue'
import type { Ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import type {
  ElementInterface,
  FieldConfigInterface,
  FieldInterface,
  GroupInterface,
  QuerySliceInterface,
  SliceConfigInterface,
  SliceInterface,
} from '@voix/types'

import { usePageStore } from './pageStore'
import { useCookie } from '#imports'

const broadcast = useVoixBroadcast()

export const useAdminStore = defineStore('adminStore', () => {
  const isZenMode: Ref<boolean> = ref(false)
  const isLoading: Ref<boolean> = ref(false)
  const currentScale: Ref<number> = ref(1)
  const currentDomain: Ref<string | null> = ref(null)
  const currentSection: Ref<null | 'editor' | 'settings' | 'templatizer'> = ref(null)
  const focusMode: Ref<boolean> = ref(false)
  const releaseId: Ref<string | null> = ref(null)
  const scrollToElement = ref(<ElementInterface | null>{})
  const highlightElement = ref(<ElementInterface>{})
  const selectedElements = ref(<Array<ElementInterface | SliceInterface | GroupInterface | QuerySliceInterface>>[])
  const selectedField = ref(<FieldConfigInterface>{})
  const selectedFieldSlice = ref(<SliceInterface>{})
  const currentlySelectedTemplatizerSlice: SliceConfigInterface['name'] = reactive({ label: '', group: '' })
  const isDragging: Ref<boolean> = ref(false)

  //  Theme color values and functions
  const themeColor: Ref<string> = ref('#4338ca')
  const voixAdminThemeColorCookie = useCookie('voix-admin-color')

  function setThemeColor(color: string) {
    voixAdminThemeColorCookie.value = color
    themeColor.value = color
  }

  if (voixAdminThemeColorCookie.value)
    themeColor.value = voixAdminThemeColorCookie.value

  // Selected field data
  const selectedFieldData = computed(() => {
    if (selectedFieldSlice.value && selectedField.value.type) {
      // We're expecting fields. If there aren't any yet
      // then we'll get setup for them.
      if (!selectedFieldSlice.value.fields)
        selectedFieldSlice.value.fields = []

      // We are looking into the data of the selected slice
      // to try and find any existing data for the field the user
      // has selected. It may not be there because the developer
      // may have added a field to the slice after the slice was
      // added to the page or it may be a new slice that was just
      // added to the page.
      const foundField = selectedFieldSlice.value.fields?.find(
        (f: any) => f.name === selectedField.value.name,
      )

      return foundField
    }
    return null
  })

  function setReleaseId(rid: string) {
    // Guard for useCookie
    const releasePreviewCookie = useCookie('voix-release-preview')
    releasePreviewCookie.value = rid
    releaseId.value = rid
  }
  function unsetReleaseId() {
    const releasePreviewCookie = useCookie('voix-release-preview')
    releasePreviewCookie.value = null
    releaseId.value = null
  }

  function setSelectedElement(element: ElementInterface) {
    selectedField.value = {} as FieldConfigInterface
    selectedElements.value = [element]
  }

  function unselectField() {
    selectedField.value = {} as FieldConfigInterface
    selectedFieldSlice.value = <SliceInterface>{}
  }

  function unsetSelectedElement() {
    selectedField.value = {} as FieldConfigInterface
    selectedElements.value = []
    unselectField()
  }

  function addToSelectedElements(element: ElementInterface) {
    selectedElements.value.push(element)
  }

  function removeFromSelectedElements(element: ElementInterface) {
    selectedElements.value.splice(selectedElements.value.indexOf(element), 1)
  }

  function unselectElements() {
    selectedElements.value = []
    unselectField()
  }

  function setHighlightedElement(element: ElementInterface) {
    highlightElement.value = element
  }
  function unsetHighlightedElement() {
    highlightElement.value = {} as ElementInterface
  }

  function selectField(slice: SliceInterface, fieldConfig: FieldConfigInterface) {
    unselectField()
    setupSliceFields(slice, fieldConfig)

    selectedFieldSlice.value = slice
    selectedField.value = fieldConfig
  }

  function toggleSelectField(
    slice: SliceInterface,
    fieldConfig: FieldConfigInterface,
  ) {
    if (fieldConfig.name === selectedField.value.name)
      unselectField()

    else
      selectField(slice, fieldConfig)
  }

  function setupSliceFields(slice: SliceInterface, fieldConfig: FieldConfigInterface, shouldBroadcast = true) {
    if (slice?.id) {
      // Make sure there are fields to worry about
      const pageStore = usePageStore()
      const sliceData = pageStore.findElement(slice.id)?.element

      if (Object.keys(fieldConfig).length && sliceData && sliceData.fields) {
        // Confirm fields is present

        const foundField = sliceData.fields?.find(
          f => f.name === fieldConfig.name,
        )
        if (!foundField) {
          const newField = {
            id: uuidv4(),
            name: fieldConfig.name,
            type: fieldConfig.type,
            enabled: Object.prototype.hasOwnProperty.call(fieldConfig, 'enabled') ? fieldConfig.enabled : true,
            properties: [],
          } as FieldInterface
          newField[fieldConfig.type] = fieldConfig.default

          if (!sliceData.fields)
            sliceData.fields = [newField]

          else
            sliceData.fields.push(newField)

          if (shouldBroadcast)
            broadcast.share('setupSliceFields', { slice, fieldConfig })
        }
      }
    }
  }

  // The way we transfer data from window to window
  if (typeof window !== 'undefined') {
    const urlParams = queryString.parse(window.location.search)
    if (urlParams['voix-sync'] === 'true') {
      broadcast.receive('setupSliceFields', (data: { slice: SliceInterface, fieldConfig: FieldConfigInterface }) => {
        setupSliceFields(data.slice, data.fieldConfig, false)
      })
    }
  }

  return {
    // State
    isZenMode,
    isLoading,
    currentScale,
    currentDomain,
    currentSection,
    focusMode,
    releaseId,
    scrollToElement,
    highlightElement,
    selectedElements,
    selectedField,
    selectedFieldSlice,
    themeColor,
    isDragging,

    // Getters
    selectedFieldData,

    // Actions
    setThemeColor,
    setReleaseId,
    unsetReleaseId,
    setSelectedElement,
    unsetSelectedElement,
    addToSelectedElements,
    removeFromSelectedElements,
    unselectElements,
    setHighlightedElement,
    unsetHighlightedElement,
    selectField,
    toggleSelectField,
    setupSliceFields,
    currentlySelectedTemplatizerSlice,

  }
})
