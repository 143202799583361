import { defineStore } from 'pinia'
import queryString from 'query-string'
import { useVoixBroadcast } from '@voix/composables/useVoixBroadcast'
import { v4 as uuidv4 } from 'uuid'
import type { StoreDefinition } from 'pinia'

import type {
  ElementInterface,
  FoundElement,
  PageInterface,
  QuerySliceInterface,
  SliceInterface,
  // MediaFieldInterface,
} from '../types'
import type { GroupInterface } from './../types/groups.d'

export interface PageStoreInterface {
  currentPath: string
  currentDomain: string
  currentPage: PageInterface | null
  pageResponse: object
  hasSetupBroadcast: boolean
}

const broadcast = useVoixBroadcast()

function resetAllIds(element: ElementInterface) {
  if (element.id)
    element.id = uuidv4()

  if (element.fields) {
    for (const field of element.fields) {
      field.id = uuidv4()

      if (field.media && field.media.length) {
        for (const media of field.media)
          media.id = uuidv4()
      }
    }
  }

  if (element.elements) {
    for (const e of element.elements)
      resetAllIds(e)
  }
}

export const usePageStore: StoreDefinition = defineStore('pageStore', {
  state: (): PageStoreInterface =>
    ({
      currentPath: '',
      currentDomain: '',
      currentPage: <PageInterface | null>null,
      pageResponse: <object>{},
      hasSetupBroadcast: false,
    }),

  actions: {
    setCurrentPage(page: PageInterface, shouldBroadcast = true) {
      let thePage = JSON.parse(JSON.stringify(page))

      thePage = this.checkAndSetupElements(thePage)
      thePage = this.setupForAdministration(thePage)

      this.currentPage = thePage

      this.setUpBroadcastIfNecessary()

      if (shouldBroadcast)
        broadcast.share('setCurrentPage', { page })
    },

    unsetCurrentPage() {
      this.currentPage = null
    },

    // Setup each of the elements to ensure they have the correct properties
    checkAndSetupElements(page: PageInterface) {
      if (page) {
        for (const element of page.elements) {
          if (!page.elements[page.elements.indexOf(element)].properties)
            page.elements[page.elements.indexOf(element)].properties = { id: '', settings: [] }

          if (!page.elements[page.elements.indexOf(element)].properties.id)
            page.elements[page.elements.indexOf(element)].properties.id = ''
        }
      }

      return page
    },

    // moved from bottom of file to run only once when pageStore is created twice on a project
    setUpBroadcastIfNecessary() {
      if (!this.hasSetupBroadcast && typeof window !== 'undefined') {
        this.hasSetupBroadcast = true
        const urlParams = queryString.parse(window.location.search)
        if (urlParams['voix-sync'] === 'true') {
          broadcast.receive('setCurrentPage', (data) => {
            this.setCurrentPage(data.page, false)
          })
          broadcast.receive('addElement', (data) => {
            this.addElement(data.element, data.destinationId, false)
          })
          broadcast.receive('duplicateElement', (data) => {
            this.duplicateElement(data.elementId, data.newElement, false)
          })
          broadcast.receive('deleteElement', (data) => {
            this.deleteElement(data.elementId, false)
          })
          broadcast.receive('moveElement', (data) => {
            this.moveElement(data.elementId, data.destinationId, false)
          })
          broadcast.receive('setSlot', (data) => {
            this.setSlot(data.elementId, data.slot, false)
          })
          broadcast.receive('moveElementIntoElement', (data) => {
            this.moveElementIntoElement(data.elementId, data.destinationId, false)
          })
          broadcast.receive('fieldUpdate', (data) => {
            this.updateField(data.sliceId, data.fieldName, data.value, false)
          })
          broadcast.receive('toggleEnableField', (data) => {
            this.toggleEnableField(data.sliceId, data.fieldName, data.value, false)
          })
          broadcast.receive('settingsUpdate', (data) => {
            this.updateElementSettings(data.elementId, data.settings, false)
          })
          broadcast.receive('updateLayout', (data) => {
            this.updateLayout(data.layout, false)
          })
        }
      }
    },

    // Sets up the page for administration including starting the loop
    // to add the administration data to each of the elements
    setupForAdministration(page: PageInterface) {
      if (page) {
        for (const element of page.elements) {
          page.elements[page.elements.indexOf(element)]
            = this.addAdministationToElement(element)
        }
      }

      return page
    },

    // Adds all the necessary administration data to an element
    addAdministationToElement(element: ElementInterface) {
      element.administration = {
        gui: { open: false, hydrationOptions: [], el: null },
        permissions: [],
      }
      if (element.elements) {
        for (const e of element.elements) {
          element.elements[element.elements.indexOf(e)]
            = this.addAdministationToElement(e)
        }
      }
      return element
    },

    getGroupById(id: number) {
      let theGroup: null | GroupInterface = null

      if (this.currentPage) {
        // TODO: Will have to refactor for nested groups
        for (const element of this.currentPage.elements) {
          if (
            element.type === 'group'
            && element.id?.toString() === id.toString()
          )
            theGroup = element
        }
      }

      return theGroup
    },

    updateSliceModelQuery(element: ElementInterface, modelQuery: any) {
      if (this.currentPage && element) {
        if (modelQuery) {
          element.model_query = modelQuery
          element.type = 'query'
        }
        else {
          element.type = 'slice'
          delete element.model_query
        }
      }
    },

    toggleEnableField(sliceId: string, fieldName: string, value: boolean, shouldBroadcast = true) {
      if (this.currentPage) {
        const foundElement: FoundElement | undefined = this.findElement(sliceId)
        if (foundElement) {
          const element = foundElement.element

          if (element && element.type === 'slice') {
            const slice = element as SliceInterface
            if (slice.fields) {
              const field = slice.fields.find(f => f.name === fieldName)
              if (field && field.type) {
                field.enabled = value

                if (shouldBroadcast)
                  broadcast.share('toggleEnableField', { sliceId, fieldName, value })
              }
            }
          }
        }
        else {
          console.error('Could not find element', sliceId)
        }
      }
    },

    enableField(sliceId: string, fieldName: string) {
      this.toggleEnableField(sliceId, fieldName, true)
    },
    disableField(sliceId: string, fieldName: string) {
      this.toggleEnableField(sliceId, fieldName, false)
    },

    openElement(element: ElementInterface) {
      if (element && element.administration && element.administration.gui)
        element.administration.gui.open = true
    },

    closeElement(element: ElementInterface) {
      if (element && element.administration && element.administration.gui)
        element.administration.gui.open = false
    },

    toggleAdminGuiOpen(sliceId: string) {
      if (this.currentPage) {
        const foundElement: FoundElement | undefined = this.findElement(sliceId)
        if (foundElement?.element?.administration?.gui?.open)
          foundElement.element.administration.gui.open = !foundElement.element.administration?.gui.open

        else
          console.error('Could not find element', sliceId)
      }
    },

    addElement(element: ElementInterface | SliceInterface | GroupInterface | QuerySliceInterface, destinationId?: string | null, shouldBroadcast = true) {
      if (this.currentPage && element) {
        const finalDestination: Array<ElementInterface | SliceInterface | GroupInterface | QuerySliceInterface> | undefined = destinationId ? this.findElement(destinationId)?.element.elements : this.currentPage.elements

        if (finalDestination) {
          finalDestination.push(element)

          if (shouldBroadcast)
            broadcast.share('addElement', { element, destinationId })
        }
      }
    },

    duplicateElement(elementId: string, newElement: ElementInterface | null = null, shouldBroadcast = true) {
      if (this.currentPage && elementId) {
        const foundElement: FoundElement | undefined = this.findElement(elementId)
        const finalDestination: ElementInterface | PageInterface | undefined = foundElement?.parentElement ? foundElement.parentElement : this.currentPage
        if (foundElement && finalDestination && finalDestination.elements) {
          if (!newElement) {
            newElement = JSON.parse(JSON.stringify(foundElement.element)) as ElementInterface
            resetAllIds(newElement)
          }

          if (newElement) {
            finalDestination.elements.push(
              newElement,
            )

            if (shouldBroadcast)
              broadcast.share('duplicateElement', { elementId, newElement })
          }
        }
        else { console.warn('Could not find element or could not identify the elements parent. Did you just delete it?', elementId, foundElement) }
      }
    },

    deleteElement(elementId: string, shouldBroadcast = true) {
      if (this.currentPage && elementId) {
        const foundElement: FoundElement | undefined = this.findElement(elementId)
        if (foundElement && foundElement.parentElement?.elements) {
          foundElement.parentElement.elements.splice(
            foundElement.parentElement.elements.indexOf(foundElement.element),
            1,
          )

          if (shouldBroadcast)
            broadcast.share('deleteElement', { elementId })
        }
        else { console.warn('Could not find element or could not identify the elements parent. Did you just delete it?', elementId, foundElement) }
      }
    },

    moveElement(elementId: string, destinationId: string | null, shouldBroadcast = true) {
      // If destinationId is null, then we are moving to the root
      const destinationIsRoot = destinationId === null

      const foundElement = this.findElement(elementId)

      if (foundElement && this.currentPage) {
        const splicedItem = foundElement.parentElement.elements.splice(
          foundElement.parentElement.elements.indexOf(foundElement.element),
          1,
        )

        if (destinationIsRoot) {
          this.currentPage.elements.unshift(splicedItem[0])
        }
        else {
          const foundDestinationElement = this.findElement(destinationId)
          if (foundDestinationElement && foundDestinationElement.element.elements) {
            foundDestinationElement.parentElement.elements.splice(
              foundDestinationElement.parentElement.elements.indexOf(foundDestinationElement.element) + 1,
              0,
              splicedItem[0],
            )
          }
        }

        if (shouldBroadcast)
          broadcast.share('moveElement', { elementId, destinationId })
      }
    },

    setSlot(elementId: string, slot: string | null, shouldBroadcast = true) {
      const foundElement = this.findElement(elementId)
      if (foundElement && slot) {
        foundElement.element.slot = slot

        if (shouldBroadcast)
          broadcast.share('setSlot', { elementId, slot })
      }
    },

    moveElementIntoElement(elementId: string, destinationId: string, shouldBroadcast = true) {
      const foundElement = this.findElement(elementId)
      const foundDestinationElement = this.findElement(destinationId)

      // If we have a foundElement, foundDestinationElement, and they are not the same
      if (foundElement && foundDestinationElement && foundElement.element.id !== foundDestinationElement.element.id && this.currentPage) {
        // Rip that sucker from it's mother
        const splicedItem = foundElement.parentElement.elements.splice(
          foundElement.parentElement.elements.indexOf(foundElement.element),
          1,
        )

        // Put it in it's new home
        if (foundDestinationElement && foundDestinationElement.element.elements) {
          foundDestinationElement.element.elements.splice(
            foundDestinationElement.element.elements.indexOf(foundDestinationElement.element) + 1,
            0,
            splicedItem[0],
          )
        }

        // Share it with our friends
        if (shouldBroadcast)
          broadcast.share('moveElementIntoElement', { elementId, destinationId })
      }
    },

    updateField(sliceId: string, fieldName: string, value: any, shouldBroadcast = true) {
      if (this.currentPage) {
        const foundElement: FoundElement | undefined = this.findElement(sliceId)
        if (foundElement) {
          const element = foundElement.element

          if (element && element.type === 'slice') {
            const slice = element as SliceInterface
            if (slice.fields) {
              const field = slice.fields.find(f => f.name === fieldName)
              if (field && field.type) {
                field[field.type] = value

                if (shouldBroadcast)
                  broadcast.share('fieldUpdate', { sliceId, fieldName, value })
              }
            }
          }
        }
        else {
          console.error('Could not find element', sliceId)
        }
      }
    },

    updateElementSettings(elementId: string, settings: any, shouldBroadcast = true) {
      if (this.currentPage) {
        const foundElement: FoundElement | undefined = this.findElement(elementId)
        if (foundElement) {
          foundElement.element.properties.settings = settings

          if (shouldBroadcast)
            broadcast.share('settingsUpdate', { elementId, settings })
        }

        else { console.error('Could not find element', elementId) }
      }
    },

    updateLayout(layout: any, shouldBroadcast = true) {
      if (this.currentPage) {
        this.currentPage.layout = layout
        if (shouldBroadcast)
          broadcast.share('updateLayout', { layout })
      }
    },

    setPageResponse(responseData: object) {
      this.pageResponse = responseData
    },

    getParentElement(element: ElementInterface): ElementInterface | null {
      const foundElement = this.findElement(element.id)
      if (foundElement)
        return foundElement.parentElement

      return null
    },

  },

  getters: {
    findElement(state) {
      return (elementId: string): FoundElement | undefined => {
        if (state.currentPage)
          return this.recursivelyFindElement(elementId, state.currentPage)

        return undefined
      }
    },

    recursivelyFindElement() {
      return (elementId: string, parentElement: PageInterface | ElementInterface): FoundElement | undefined => {
        if (parentElement.elements) {
          for (const element of parentElement.elements) {
            if (element?.id?.toString() === elementId.toString())
              return { element, parentElement, index: parentElement.elements.indexOf(element) }

            if (element.elements) {
              const result = this.recursivelyFindElement(elementId, element)
              if (result)
                return result
            }
          }
        }
        return undefined
      }
    },
  },
})
