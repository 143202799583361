import type { DirectiveBinding } from 'vue'

const vVoixVisibility = {
  // called before bound element's attributes
  // or event listeners are applied
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: binding.value.threshold,
    }

    const observer = new IntersectionObserver((entires) => {
      entires.forEach((entry) => {
        binding.value.callback(entry)
      })
    }
    , options)

    observer.observe(el)
  },
}

export default vVoixVisibility
