import { addRouteMiddleware, useCookie, useRuntimeConfig } from '#imports'

export default () => {
  // Add the middleware to intercept the auth callback routes
  // The primary purpose of this middleware is to handle the SSO login flow
  addRouteMiddleware(async (to) => {
    if (typeof window !== 'undefined') {
      const config = useRuntimeConfig()

      // We are redirected here by the SSO server
      if (to.path === '/voix/auth/callback') {
        const authStateCookie = useCookie('auth-state')
        const authVerifierCookie = useCookie('auth-verifier')

        // If the current site is set, and we have an auth state and verifier cookie,
        // and the state matches the cookie then we'll submit for a token
        if (config.public.voixAuthTokenUrl && authStateCookie.value && authVerifierCookie.value && to.query.state === authStateCookie.value) {
          const baseUrl = window.location.origin

          await $fetch(`${config.public.voixApiBaseUrl}${config.public.voixAuthTokenUrl}`, {
            method: 'POST',
            body: {
              grant_type: 'authorization_code',
              client_id: config.public.voixAuthClientId,
              redirect_uri: `${baseUrl}${config.public.voixAuthRedirect}`,
              code_verifier: authVerifierCookie.value,
              code: to.query.code,
            },
          }).then((response) => {
            authStateCookie.value = null
            // authVerifierCookie.value = null

            const refreshToken = useCookie('auth-refresh-token', { maxAge: 60 * 60 * 24 * 30 * 5 })
            const accessToken = useCookie('auth-access-token', { maxAge: 60 * 60 * 24 * 30 * 5 })

            refreshToken.value = response.refresh_token
            accessToken.value = response.access_token
          }).catch((error) => {
            console.error(error)
          })
        }
        else {
          return window.location.href = '/voix'
        }
      }
    }
  })
}
