import { defineStore } from 'pinia'
import type { BreakpointInterface } from '@voix/types'

const useBreakpointStore = defineStore('breakpointStore', {
  // TODO: These should be set by the config
  state: () => ({
    // TODO: Breakpoints MUST stay in order of smallest minWidth to largest minWidth
    breakpoints: [
      {
        name: 'default',
        minWidth: 0,
        icon: '',
      } as BreakpointInterface,
      {
        name: 'sm',
        minWidth: 640,
        icon: `<svg
        class="w-6 h-6 transform rotate-90"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
        ></path>
      </svg>`,
      } as BreakpointInterface,
      {
        name: 'md',
        minWidth: 768,
        icon: '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path></svg>',
      } as BreakpointInterface,
      {
        name: 'lg',
        minWidth: 1024,
        icon: '<svg class="w-6 h-6 transform rotate-90" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path></svg>',
      } as BreakpointInterface,
      {
        name: 'xl',
        minWidth: 1280,
        icon: '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>',
      } as BreakpointInterface,
      {
        name: '2xl',
        minWidth: 1536,
        icon: '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>',
      } as BreakpointInterface,
    ],
  }),

  getters: {
    getAllBreakpoints: (state): Array<BreakpointInterface> => {
      // Return all breakpoints in order of minWidth
      return state.breakpoints.sort((a, b) => a.minWidth - b.minWidth)
    },
  },
})

export { useBreakpointStore }
